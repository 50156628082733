/* Main title styling */
body, .game-container, .grid-container {
  font-family: 'Roboto', sans-serif;
}

body {
  background: linear-gradient(180deg, #1c1c38, #4f5265);
  min-height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  padding-bottom: 1em
}

h1, h2, h3, h4 {
  color: #e5d9ee;
}


button.btn-primary {
  background-color: #2980b9;
  border: none;
  color: #ecf0f1;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button.btn-primary:hover {
  background-color: #3498db;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
}

.game-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.game-container {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* Prevent overflow */
  color: #F8F8F2;
  font-family: "Roboto", Arial, sans-serif;
}

.game-content {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
}

.grid-container {
  padding: 20px;
  border-radius: 8px;
  background-color: hsla(235, 14%, 17%, 0.85);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.grid-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

table {
  border-spacing: 0;
}

.box {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: hsl(233, 13%, 13%);
  color: #F8F8F2;
  transition: background-color 0.2s ease;
  border: none;
  margin: 2px;
}

.box.revealed {
  background-color: #3c3f50;
  color: #F8F8F2;
  border: none;
}

.box.revealed::after {
  content: "";
}

.box.revealed-mine {
  background-color: #5a6f89;
  border: none;
}

.box.triggered-mine {
  background-color: #6e83a1;
  border: none;
}

.box.revealed[data-value="1"] { color: #3498db; }
.box.revealed[data-value="2"] { color: #e74c3c; }
.box.revealed[data-value="3"] { color: #f1c40f; }
.box.revealed[data-value="4"] { color: #9b59b6; }
.box.revealed[data-value="5"] { color: #e67e22; }
.box.revealed[data-value="6"] { color: #1abc9c; }
.box.revealed[data-value="7"] { color: #c0392b; }
.box.revealed[data-value="8"] { color: #7f8c8d; }

.box.flagged {
  background-color: #3b2727;
  color: #ffd700;
  font-weight: bold;
}

.game-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 700px;
  padding: 2em 4em;
  text-align: center;
  font-size: 2em;
  font-family: 'Roboto Mono', monospace;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  cursor: pointer;
}

/* Overlay colors */
.game-over-overlay {
  background-color: rgba(66, 39, 39, 0.9); /* Dark red with opacity */
  color: #f8d7da; /* Pale red text */
}

.congrats-message {
  background-color: rgba(50, 118, 78, 0.9); /* Dark green with opacity */
  color: #a4debc; /* Bright green text */
}

.wide-ad {
  width: 300px;
  margin-left: 20px;
  display: none;
  position: absolute;
  right: -320px;
}

.bottom-ad {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  display: none;
}

@media (min-width: 992px) {
  .wide-ad {
    display: block;
  }
}

@media (max-width: 991px) {
  .bottom-ad {
    display: block;
  }
}

ins.adsbygoogle {
    display:block;
}
